import React from "react";
import { Link } from "gatsby";
import "../scss/global.scss";
import Tom_Butler from "../images/Tom_Butler.png";
import Tamsin from "../images/tamsin.jpg";
import Albert from "../images/Albert.jpeg";
import Michael from "../images/michael.png";
import Orlandoreid_logo from "../images/or-master.png";
import Sheraton_logo from "../images/Group.png";
import Lauren_Van_Veen from "../images/Lauren-Van-Veen.jpeg";
import $ from "jquery";


const sheratonlawPage = () => {
     
   setTimeout(() => {
      const link = document.createElement('a');
      link.href = "/";
      document.body.appendChild(link);
      link.click();
      }, 7000)
  
  return (<React.Fragment>
    <div className="content null">
      <div className="sheratonlawpage">
        <div className="sheratonlaw_Page">
          <div className="logos">
            <Link to="https://www.sheratonlaw.com/" target="_blank"><img src={Sheraton_logo} alt="Sheraton_logo" /></Link>
            <Link to="/"><img src={Orlandoreid_logo} alt="Orlandoreid_logo" /></Link>
          </div>
          <h1>We are delighted to announce that Sheraton Law is now part of Orlando Reid's network. </h1>
          <p className="intro-text">We continue to be actively involved in the business and look forward to being of service.</p>
          <div className="teamblock">
            <div className="team">
              <img src={Tom_Butler} alt="Tom_Butler" />
              <div className="member-name">Tom Butler</div>
            </div>
            <div className="team">
              <img src={Tamsin} alt="Tamsin" className="img-circule" />
              <div className="member-name">Tamsin Russell</div>
            </div>
            <div className="team" >
              <img src={Michael} alt="Michael" className="img-circule" />
              <div className="member-name">Michael Taylor</div>
            </div>
            <div className="team" >
              <img src={Albert} alt="Albert" className="img-circule" />
              <div className="member-name">Albert Marques</div>
            </div>
            <div className="team" >
              <img src={Lauren_Van_Veen} alt="Lauren_Van_Veen" className="img-circule" />
              <div className="member-name">Lauren Van Veen</div>
            </div>
          </div>
          <p className="bottom_text">Please click <Link to="/" className={"tbtn"}>here</Link> to visit our website</p>
        </div>
      </div>
    </div>
  </React.Fragment>)

 
}
export default sheratonlawPage
